import { defaultEmailStyleString, genInternalId, TokenPrefix } from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { AdvancedSearchEntityType, useRelocateImageMutation } from "generated/graphql";
import React, {
    forwardRef,
    ForwardRefExoticComponent,
    RefAttributes,
    useImperativeHandle,
} from "react";
import {
    AutoFormatPlugin,
    EditorPlugin,
    EditPlugin,
    HyperlinkPlugin,
    PastePlugin,
    PluginEvent,
    ShortcutPlugin,
} from "roosterjs";
import { EditorAdapter, EditorAdapterOptions } from "roosterjs-editor-adapter";
import { breakNewlines } from "util/text";
import { Ribbon } from "./ribbon";
import { HtmlToken, Template } from "./template";

interface Props {
    initialContent?: string;
    templates?: Template[];
    signatures?: Template[];
    onTemplateInserted?: (template: Template) => void;
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
    placeholderEntity?: unknown | null;
    onBlur?: () => void;
    placeholderTypes?: AdvancedSearchEntityType[];
    tokens?: HtmlToken[];
    preserveTokens?: TokenPrefix[];
}

const useForceUpdate = () => {
    const s = React.useState(0);
    return () => s[1](value => value + 1); // update the state to force render
};

const useUnmount = (fn: () => void) => {
    React.useEffect(() => {
        return fn;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export interface PlacementEditor {
    getContent: () => string;
}

interface PastedImage {
    id: string;
    src: string;
}

class ImagePasteExtractor implements EditorPlugin {
    constructor(private onImagesPasted: (images: PastedImage[]) => void) {}

    getName() {
        return "ImagePasteExtractor";
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initialize(editor: EditorAdapter) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispose() {}

    private shouldHandleImage(src: string) {
        if (!src) {
            return false;
        }

        if (src.startsWith("http://") || src.startsWith("https://")) {
            return true;
        }

        if (src.startsWith("data:image/") && src.includes(";base64,")) {
            return true;
        }

        return false;
    }

    onPluginEvent(event: PluginEvent) {
        const imageUrls: PastedImage[] = [];

        if (event.eventType === "beforePaste") {
            event.fragment.querySelectorAll("img").forEach(img => {
                if (this.shouldHandleImage(img.src)) {
                    const id = genInternalId();
                    img.setAttribute("data-relocate-image-id", id);
                    imageUrls.push({
                        id,
                        src: img.src,
                    });
                }
            });
        }

        if (imageUrls.length > 0) {
            this.onImagesPasted(imageUrls);
        }
    }
}

const createEditor = (
    contentDiv: HTMLDivElement,
    initialContent: string | undefined,
    onImagesPasted: (images: PastedImage[]) => void,
): EditorAdapter => {
    if (!contentDiv.className.includes("roosterjs")) {
        contentDiv.className += " roosterjs";
    }
    const plugins: EditorPlugin[] = [
        new PastePlugin(),
        new EditPlugin(),
        new AutoFormatPlugin(),
        new ImagePasteExtractor(onImagesPasted),
        new HyperlinkPlugin(),
        new ShortcutPlugin(),
    ];
    const options: EditorAdapterOptions = {
        plugins,
        initialContent,
    };
    return new EditorAdapter(contentDiv, options);
};

const Editor: ForwardRefExoticComponent<Props & RefAttributes<PlacementEditor>> =
    forwardRef<PlacementEditor, Props>((props, ref) => {
        const {
            initialContent,
            templates,
            signatures,
            onTemplateInserted,
            tokenReplacements,
            onTokenInserted,
            onBlur,
            placeholderTypes,
            tokens,
            preserveTokens,
        } = props;
        const editorRef = React.useRef<EditorAdapter>();
        const update = useForceUpdate();
        const [editorError, setError] = React.useState<string | null>(null);

        const mutation = useRelocateImageMutation();

        const markImageAsFailed = React.useCallback(
            (imageId: string) => {
                if (editorRef.current) {
                    const imgElement = editorRef.current
                        .getDocument()
                        .querySelector(`[data-relocate-image-id="${imageId}"]`);

                    if (imgElement) {
                        imgElement.setAttribute("src", "");
                        imgElement.setAttribute("alt", "Image failed to load");
                        imgElement.setAttribute(
                            "style",
                            `background-color: rgb(220, 38, 38); color: white;`,
                        );

                        setError(
                            "Some images in your pasted content will not display properly in your email. To correct these:\n\n1. Locate the source image in your original document or email.\n2. Right-click the image and select 'Copy image'.\n3. Paste the image into your favorite editor to size it appropriately.\n4. Paste the right-sized image here in place of the broken one.",
                        );
                    }
                }
            },
            [editorRef, setError],
        );

        const handleImagesPasted = React.useCallback(
            async (images: PastedImage[]) => {
                for (const image of images) {
                    try {
                        const result = await mutation.mutateAsync({
                            payload: {
                                id: image.id,
                                src: image.src,
                            },
                        });

                        if (
                            result.relocateImage?.src &&
                            editorRef.current &&
                            result.relocateImage.src !== image.src
                        ) {
                            editorRef.current
                                .getDocument()
                                .querySelector(`[data-relocate-image-id="${image.id}"]`)
                                ?.setAttribute("src", result.relocateImage.src);
                        } else {
                            markImageAsFailed(image.id);
                        }
                    } catch (e) {
                        markImageAsFailed(image.id);
                        console.warn(e);
                    }
                }
            },
            [editorRef, markImageAsFailed, mutation],
        );

        const init = React.useCallback(
            (el: HTMLDivElement) => {
                if (el && !editorRef.current) {
                    el.setAttribute("style", defaultEmailStyleString);
                    editorRef.current = createEditor(
                        el,
                        initialContent,
                        handleImagesPasted,
                    );
                    //createEditor(el, [], initialContent);
                    update();
                }
            },
            [editorRef, handleImagesPasted, initialContent, update],
        );

        const handleBlur = React.useCallback(() => {
            if (editorRef.current && onBlur) {
                onBlur();
            }
        }, [editorRef, onBlur]);

        useImperativeHandle(
            ref,
            () => {
                return {
                    getContent: () =>
                        editorRef.current ? editorRef.current.getContent() : "",
                };
            },
            [editorRef],
        );
        useUnmount(() => {
            if (editorRef.current) {
                editorRef.current.dispose();
            }
        });
        return (
            <div>
                {editorRef.current ? (
                    <Ribbon
                        editor={editorRef.current}
                        templates={templates}
                        signatures={signatures}
                        onTemplateInserted={onTemplateInserted}
                        tokenReplacements={tokenReplacements}
                        onTokenInserted={onTokenInserted}
                        placeholderTypes={placeholderTypes}
                        tokens={tokens}
                        preserveTokens={preserveTokens}
                    />
                ) : null}
                <div
                    className="unreset rounded-b overflow-scroll h-80 border-2 border-gray-200 p-4 min-w-full"
                    onBlur={handleBlur}
                    ref={init}></div>
                {editorError ? (
                    <div className="mt-2">
                        <InlineBanner type="error">
                            {breakNewlines(editorError)}
                        </InlineBanner>
                    </div>
                ) : null}
            </div>
        );
    });

Editor.displayName = "Editor";

export { Editor };
